<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Profil PPB</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <h4 class="mb-10 font-weight-bold text-dark">
            INFORMASI IDENTITAS PPB
          </h4>
          <!--begin::Input-->
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Provinsi/Kabupaten/Kota</label
            >
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.daerah"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Nomor Surat Keputusan Pembentukan Tim</label
            >
            <div class="col-lg-8">
              <input
                type="text"
                v-model="profil.surat_penugasan"
                class="form-control"
              />
            </div>
          </div>
          <!-- <div class="form-group row">
                    <label class="col-4">Nomenklatur Sesuai Permendagri No 25 Tahun 2021</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.nomenklatur_permendagri" :options="permendagri" style="width: 150px;"></b-form-select>
                        </div>
                </div> -->
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Ketua Tim</label>
            <div class="col-lg-8">
              <input type="text" v-model="profil.ketua" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Anggota</label>
            <div class="col-lg-8">
              <b-form-tags
                input-id="tags-basic"
                v-model="profil.anggota_array"
              ></b-form-tags>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Alamat Kantor</label>
            <div class="col-lg-8">
              <input type="text" v-model="profil.alamat" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Nomor telepon/faksimili kantor/sekretariat</label
            >
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-6">
                  <!--begin::Input-->
                  <div class="form-group">
                    <input
                      min="0"
                      type="number"
                      v-model="profil.telepon"
                      class="form-control"
                    />
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-6">
                  <!--begin::Input-->
                  <div class="form-group">
                    <input
                      min="0"
                      type="number"
                      v-model="profil.faksimili"
                      class="form-control"
                    />
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Alamat surat elektronik kantor/sekretariat</label
            >
            <div class="col-lg-8">
              <input type="email" v-model="profil.email" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Nama narahubung</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-avatar"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        v-model="profil.nama_contact_person1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon2-phone"></i>
                        </span>
                      </div>
                      <input
                        min="0"
                        type="number"
                        v-model="profil.no_contact_person1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-email"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        v-model="profil.email_contact_person1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-avatar"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        v-model="profil.nama_contact_person2"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon2-phone"></i>
                        </span>
                      </div>
                      <input
                        min="0"
                        type="number"
                        v-model="profil.no_contact_person2"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-email"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        v-model="profil.email_contact_person2"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Titik Koordinat</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <b>latitude</b>
                        </span>
                      </div>
                      <input
                        type="text"
                        v-model="latitude"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <b>longitude</b>
                        </span>
                      </div>
                      <input
                        type="text"
                        v-model="longitude"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
              <!-- <button @click="update" style="float: right;" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Save</button> -->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-12">
                  <!--begin::Input-->
                  <div class="form-group">
                    <label>Upload Foto Tampak Depan Kantor</label>
                    <div></div>
                    <b-form-file
                      v-model="profil.tampak_depan"
                      type="file"
                      ref="file"
                    ></b-form-file>
                    <!-- <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customFile"/>
                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                    </div> -->
                  </div>
                  <b-img
                    :src="profil.tampak_depan"
                    style="height: 100px"
                    fluid
                    alt="Foto"
                  ></b-img>
                  <!--end::Input-->
                </div>
              </div>
              <!-- <div v-if="status.status_sistem === 'Buka'"> -->
              <button
                type="button"
                v-if="
                  user.id_role != 'helpdesk' || user.id_role != 'admin_helpdesk'
                "
                @click="editprofil"
                style="
                  background-color: #030a8c;
                  float: right;
                  border-color: #030a8c;
                "
                class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              >
                Simpan
              </button>
              <!-- </div> -->
            </div>
          </div>
        </div>
        <!--end::Example-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";
// @import "vue-select/src/scss/vue-select.scss";

export default {
  name: "Profil-PTSP",
  components: { vSelect },
  data() {
    return {
      urlpenilaian: localStorage.getItem("baseapipenilaian"),
      idprofil: localStorage.getItem("idprofil"),
      user: JSON.parse(localStorage.getItem("user")),
      tanggal: localStorage.getItem("tanggal"),
      status: [],
      //   tabIndex: 0
      idptsp: "",
      daerah: "",
      nomenklatur_ptsp: "",
      tahun_ptsp: "",
      alamat: "",
      kodepos: "",
      nofax: "",
      website: "",
      email: "",
      facebook: "",
      instagram: "",
      twitter: "",
      nama_kepala: "",
      nama_contact_person1: "",
      no_contact_person1: "",
      email_contact_person1: "",
      nama_contact_person2: "",
      no_contact_person2: "",
      longtitude: "",
      latitude: "",
      longitude: "",
      notelp: "",
      perizinan_dilayani: "",
      aktifitas_dilayani: "",
      sdm_pelayanan: "",
      sdm_dalaks: "",
      email_contact_person1: "",
      email_contact_person2: "",
      tampak_depan: "",
      selectedpermendagri: null,
      permendagri: [
        { value: null, text: "Silahkan Pilih" },
        { value: "Sesuai", text: "Sesuai" },
        { value: "Belum sesuai", text: "Belum sesuai" },
      ],
      selectedprovinsi: "",
      provinsi: [
        {
          id: "",
          name: "",
        },
      ],
      selectedkota: "",
      kota: [
        {
          id: "",
          name: "",
        },
      ],
      toastCount: 0,
      profil: [],
      value: ["apple", "orange"],
    };
  },
  //   computed: {
  //     ...mapGetters(["layoutConfig"]),

  //     config() {
  //       return this.layoutConfig();
  //     }
  //   },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil PPB" }]);
    this.getkegiatan();
    this.getlocation();
  },
  methods: {
    editprofil() {
      var array = this.profil.anggota_array;
      console.log(this.profil.anggota_array);
      let formData = new FormData();
      formData.append("daerah", this.profil.daerah);
      formData.append("surat_penugasan", this.profil.surat_penugasan);
      formData.append("ketua", this.profil.ketua);
      formData.append("alamat", this.profil.alamat);
      formData.append("latitude", this.latitude);
      formData.append("longtitude", this.longitude);
      formData.append("telepon", this.profil.telepon);
      formData.append("faksimili", this.profil.faksimili);
      formData.append("email", this.profil.email);
      formData.append("anggota", array.join("||"));
      formData.append("nama_contact_person1", this.profil.nama_contact_person1);
      formData.append("no_contact_person1", this.profil.no_contact_person1);
      formData.append(
        "email_contact_person1",
        this.profil.email_contact_person1
      );
      formData.append("nama_contact_person2", this.profil.nama_contact_person2);
      formData.append("no_contact_person2", this.profil.no_contact_person2);
      formData.append(
        "email_contact_person2",
        this.profil.email_contact_person2
      );
      formData.append("tampak_depan", this.profil.tampak_depan);
      // tampak_depan
      axios
        .post(
          this.urlpenilaian + "/profil_ppb_pemda/" + this.idprofil,
          formData,
          {
            // _method: 'patch',
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          //   this.$loading(false);
          this.$bvToast.toast("Berhasil Diupdate", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          this.loaddata();
          console.log(response.data.id);
          console.log(response.data);
          // $('#import form :input').val("");
          //   this.loadData(this.idcabang)

          return response;
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast("Gagal Diupdate", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    getlocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          this.latitude = pos.coords.latitude;
          this.longitude = pos.coords.longitude;
          console.log(this.latitude);
          console.log(this.longitude);
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
    getkegiatan() {
      axios
        .get(
          this.urlpenilaian +
            `/tanggal_kegiatans?filter=tanggal_mulai,<=,` +
            this.tanggal +
            `;tanggal_selesai,>=,` +
            this.tanggal
        )
        .then((response) => {
          this.status = response.data.data;
          console.log("status_sistem");
          console.log(response.data.data.status_sistem);
          this.notifikasi();
        });
    },
    loaddata() {
      axios
        .get(
          this.urlpenilaian +
            `/profil_ppb_pemdas?filter=idptsp,=,` +
            this.user.id_ptsp
        )
        .then((response) => {
          this.profil = response.data.data;
          // localStorage.setItem('idptsp', JSON.stringify(response.data.data.idptsp))
          console.log(response.data.data);
        });
    },
    notifikasi() {
      //   e.preventDefault();
      if (this.status.status_sistem === "Buka") {
        Swal.fire({
          title: "",
          text: "Penilaian Mandiri saat ini telah dibuka",
          icon: "info",
          confirmButtonClass: "btn btn-secondary",
        });
      } else if (this.status.status_sistem === "Tutup Sementara") {
        Swal.fire({
          title: "",
          text: "Penilaian Mandiri saat ini telah ditutup sementara",
          icon: "info",
          confirmButtonClass: "btn btn-secondary",
        });
      } else {
        Swal.fire({
          title: "",
          text: "Penilaian Mandiri saat ini telah ditutup",
          icon: "info",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
  },
  created() {
    this.loaddata();
  },
};
</script>